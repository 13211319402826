import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  FirstFormSlice: {},
  SecondFormSlice: {
    firstName: "",
    lastName: "",
    street: "",
    aptUnit: "",
    state: "",
    zipCode: "",
    city: "",
    country: "",
  },
 
  ThirdFormSlice: {
    checkbox: true,
    firstName: "",
      lastName: "",
      street: "",
      aptUnit: "",
      state: "",
      zipCode: "",
      city: "",
  },
  OrderSummary: {
    Card: "",
    expiry: "",
    cvv: "",
    checkbox: "",
  },

 
};

const CheckoutFormSlice = createSlice({
  name: "storeCheckoutForm",
  initialState: initialState,
  reducers: {
    setCheckoutFormSlice(state: any, action) {
      state.FirstFormSlice = action.payload;
    },
    setCheckoutSecondFormSlice(state: any, action) {
      state.SecondFormSlice = action.payload;
    },

    setCheckoutOrderSummarySlice(state: any, action) {
      state.OrderSummary = action.payload;
    },

    setCheckoutThirdFormSlice(state: any, action) {
      state.ThirdFormSlice = action.payload;
    },
     
  },
});
export const {
  setCheckoutFormSlice,
  setCheckoutSecondFormSlice,
  setCheckoutOrderSummarySlice,
  setCheckoutThirdFormSlice,
  
} = CheckoutFormSlice.actions;
export default CheckoutFormSlice.reducer;
