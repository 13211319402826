import { createSlice } from "@reduxjs/toolkit";

const initialState = { data: {} };

const storeDataSlice = createSlice({
  name: "storeDataSlice",
  initialState: initialState,
  reducers: {
    setStoreData(state: any, action) {
      state.data = action.payload;
    },
  },
});
export const { setStoreData } = storeDataSlice.actions;
export default storeDataSlice.reducer;
