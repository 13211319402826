import { createSlice } from "@reduxjs/toolkit";

const StoreDesignListSlice = createSlice({
  name: "storeDesignList",
  initialState: {
    data: {
      id: 0,
      club_logo: null,
      banner_image: null,
      is_team_filter_show: null,
      team_filter_title: null,
      team_filter_description: null,
      is_vector_image_show: null,
      vector_image_title: null,
      vector_image_description: null,
      vector_image_button_text: null,
      is_our_product_section_show: null,
      our_product_section_title: null,
      our_product_section_description: null,
      optional_item_description: null,
      is_promise_section_show: null,
      promise_title: null,
      promise_description: null,
      deleted_at: null,
    },
    teamFilter: null,
    loader: true,
    filterLoader: false,
    searchState: false,
    clearSearch: false,
    searchType: false,
  },
  reducers: {
    setStoreDesignListSlice(state: any, action) {
      state.data = action.payload;
    },
    setStoreTeamFilterSlice(state: any, action) {
      state.teamFilter = action.payload;
    },
    setLoader(state: any, action) {
      state.loader = action.payload ?? null;
    },
    clearLoader(state: any) {
      state.loader = false;
    },
    setFilterLoader(state: any, action) {
      state.filterLoader = action.payload ?? null;
    },
    setSearch(state: any, action) {
      state.searchState = action.payload ?? null;
    },
    setClearSearch(state: any, action) {
      state.clearSearch = action.payload ?? null;
    },
    setSearchType(state: any, action) {
      state.searchType = action.payload ?? null;
    },
  },
});
export const {
  setStoreDesignListSlice,
  setStoreTeamFilterSlice,
  setLoader,
  clearLoader,
  setFilterLoader,
  setSearch,
  setClearSearch,
  setSearchType,
} = StoreDesignListSlice.actions;
export default StoreDesignListSlice.reducer;
