import { createTheme } from "@mui/material/styles";

export const appTheme = createTheme({
  palette: {
    primary: {
      light: "#898888",
      main: "#898888",
      dark: "#898888",
      contrastText: "#fff",
    },
    secondary: {
      light: "#000",
      main: "#000",
      dark: "#000",
      contrastText: "#fff",
    },
  },
});
