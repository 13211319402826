import "react-quill/dist/quill.snow.css";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./scss/App.scss";
// import Home from "./pages";
import React, { Suspense, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setCartCount } from "./redux/cart";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
// import { RotatingLines } from "react-loader-spinner";
const Home = React.lazy(() => import("./pages"));
const BundleDetail = React.lazy(() => import("./pages/bundleDetail"));
const BundleCart = React.lazy(() => import("./pages/bundleCart"));
const StoreNotFound = React.lazy(() => import("./pages/storeNotFound"));
const ComingSoon = React.lazy(() => import("./pages/comingSoon"));
const CheckoutCart = React.lazy(() => import("./pages/CheckoutCart"));
const ProductDetail = React.lazy(() => import("./pages/productDetail"));
const StoreExpired = React.lazy(() => import("./pages/storeExpired"));
const ThankYou = React.lazy(() => import("./pages/thankYou"));
const stripePromise = loadStripe(process.env.REACT_APP_PUBLISH_KEY || "");
function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    try {
      const cartDataInfo = localStorage.getItem("cart");
      const cartData = cartDataInfo ? JSON.parse(cartDataInfo) : [];

      if (cartData) {
        dispatch(setCartCount(cartData.length));
      }
    } catch (error) {
      console.error("Error accessing localStorage or parsing JSON:", error);
    }
  }, []);
  return (
    <div className="app">
      <Elements stripe={stripePromise}>
        <Suspense fallback={""}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/bundle-detail/:id" element={<BundleDetail />} />
            {/* <Route path="/bundle-detail" element={<BundleDetail />} /> */}
            <Route path="/cart" element={<BundleCart />} />
            <Route path="/checkout-cart" element={<CheckoutCart />} />
            <Route path="/thankyou" element={<ThankYou />} />
            <Route path="/store-not-found" element={<StoreNotFound />} />
            <Route path="/coming-soon" element={<ComingSoon />} />
            <Route path="/product-detail/:id" element={<ProductDetail />} />
            <Route path="/store-expired" element={<StoreExpired />} />
          </Routes>
          <ToastContainer />
        </Suspense>
      </Elements>
    </div>
  );
}

export default App;
