import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cartCount: 0,
};

const CartDataSlice = createSlice({
  name: "cartData",
  initialState,
  reducers: {
    setCartCount(state: any, action) {
      state.cartCount = action.payload;
    },
  },
});
export const { setCartCount } = CartDataSlice.actions;
export default CartDataSlice.reducer;
