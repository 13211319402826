import { createSlice } from "@reduxjs/toolkit";

const initialState = {

  productDataList: [],

};

const ProductDataSlice = createSlice({
  name: "productData",
  initialState,
  reducers: {
   
    setProductData(state: any, action) {
      state.productDataList = action.payload;
    },
    
  },
});
export const {

  setProductData,
  
} = ProductDataSlice.actions;
export default ProductDataSlice.reducer;
