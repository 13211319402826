import { configureStore } from "@reduxjs/toolkit";
import StoreDesignListSlice from "./StoreDesignList/StoreDesignListSlice";
import CheckoutFormSlice from "./CheckoutForm/CheckoutFormSlice";
import CartDataSlice from "./cart";
import ProductDataSlice from "./productData";
import StoreDataSlice from "./StoreData/StoreDataSlice";

export const store = configureStore({
  reducer: {
    storeDesignList: StoreDesignListSlice,
    storeCheckoutForm: CheckoutFormSlice,
    cartDataSlice: CartDataSlice,
    ProductDataSlice: ProductDataSlice,
    storeDataSlice: StoreDataSlice,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
